import { toggles } from "../utils/Toggles";

function getArticleNumberArrayAsString() {
  const products: NodeList = document.querySelectorAll(".product:not(.sponsored)"),
    COUNT_PRODUCTS = 4,
    loopCount: number = products.length < COUNT_PRODUCTS ? products.length : COUNT_PRODUCTS,
    articleNumbers: Array<string> = [];

  for (let i = 0; i < loopCount; i++) {
    const articleNumber: string = (products[i] as HTMLElement).dataset.articleNumber || "";
    if (articleNumber !== "") {
      articleNumbers.push(articleNumber);
    }
  }

  return JSON.stringify(articleNumbers);
}

function getEventName(android: string, ios: string) {
  return {
    eventName: {
      android: android,
      ios: ios,
    },
  };
}

function tileListAdjustEvent(sitePath: string, android: string, ios: string) {
  const eventName = getEventName(android, ios);
  const productTracking = {
    partnerParameters: {
      articleids: getArticleNumberArrayAsString(),
      contentType: "product",
      sitePath: sitePath,
    },
  };

  return { ...eventName, ...productTracking };
}

function contentPageAdjustEvent(sitePath: string) {
  const eventName = getEventName("cyv1b3", "pcv0sa");
  const productTracking = {
    partnerParameters: {
      sitePath: sitePath,
    },
  };

  return { ...eventName, ...productTracking };
}

function defaultAdjustEvent() {
  const eventName = getEventName("tap3xe", "duht9i");
  const productTracking = {
    partnerParameters: {
      articleids: "[]",
      contentType: "product",
    },
  };

  return { ...eventName, ...productTracking };
}

export function init(sitePath: string) {
  if (window.o_apps && typeof window.o_apps.trackAdjust === "function") {
    const avContent: HTMLElement | null = document.getElementById("avContent");
    if (avContent) {
      const pageType: string = avContent.dataset.pt || "";

      switch (pageType) {
        case "Produktliste":
          window.o_apps.trackAdjust(tileListAdjustEvent(sitePath, "tap3xe", "duht9i"));
          break;
        case "Suchergebnisseite":
          window.o_apps.trackAdjust(tileListAdjustEvent(sitePath, "9ldrbv", "kxrhs8"));
          break;
        default:
          window.o_apps.trackAdjust(contentPageAdjustEvent(sitePath));
      }
    } else {
      window.o_apps.trackAdjust(defaultAdjustEvent());
    }
  }
}

let reptileAppsTrackingInitialized = false;

/**
 *
 */
export function registerAppTracking(sitePath?: string) {
  if (toggles.isActive("REPTILE_1189_RENAME_CSS_CLASS_FOR_SPAS_EXPERIMENT")) {
    return;
  }
  const path = sitePath || window.location.href;

  /*            */
  if (reptileAppsTrackingInitialized) {
    return;
  }
  reptileAppsTrackingInitialized = true;

  init(path);
}
